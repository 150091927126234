import React from "react"
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from "date-fns/esm/locale/it";
registerLocale("it", it)
setDefaultLocale(it);

const datepicker = (props) => {
    return(
        <DatePicker
            isClearable
            autoComplete="off"
            name={props.name}
            selected={props.value}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            value={props.value}
            // dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            locale="it"
            showMonthDropdown
            showYearDropdown
            fixedHeight
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            popperPlacement="bottom-start"
            popperClassName="smartbip-datepicker"
            customInput={<input className="custom-input" />}
            {...props}
        />
    );
}

export default datepicker;