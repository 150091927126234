import React from "react";
import HeaderItem from "../HeaderItems/HeaderItem";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import MyStores from '../../../../images/MyStores.png';
import { ReactComponent as UserIcon } from '../../../../images/user-2.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';

import "./new-header.styles.scss";

class NewHeader extends React.Component {
    render() {
        const { user, cart, searchInput, onChange, closeAll, order, showModalLogin, submitSearch, logoUrl } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="container-fluid">
                                <div className="row header-top">
                                    <div className="col pin">
                                        <a href="https://my-stores.it" target="_blank" rel="noopener noreferrer">
                                            <img src={MyStores} alt="my-stores" />
                                        </a>
                                    </div>
                                    {user ?
                                        <div className="col user">
                                            <div className="user-logged-container" onClick={this.props.loginModal}>
                                                <div className="user-logged">
                                                    {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col user text-right">
                                            <UserIcon onClick={showModalLogin} />
                                        </div>
                                    }
                                </div>
                                <div className="row header-bottom">
                                    <div className="col">
                                        <div className="align-items-center logo">
                                            <Link to="/" className="link" onClick={closeAll}>
                                                {/* <Logo /> */}
                                                {/* <img src={Logo} alt="" /> */}
                                                <img src={logoUrl} alt="my-stores" />
                                                {/* <span>Logo shop</span> */}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col search"></div>
                                    <div className="col actions"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(NewHeader);
