import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap'
import ValidateInput from "../../components/UI/Input/ValidateInput";
import Datepicker from "../../components/UI/Datepicker/Datepicker";
import BookingBanner from "../../images/booking-banner.jpg"
import SuccessPopup from "./SuccessPopup/SuccessPopup";
import swal from "../../utils/swal";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions"
import { bookingService } from "./Service";
import toaster from "../../utils/toaster";

import "./booking.styles.scss"

function Booking(props) {

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const [show, setShow] = useState(false);
    const [reserveInfo, setReserveInfo] = useState();
    const [date, setDate] = useState();
    const [merchantInfo, setMerchantInfo] = useState();
    const [excludeDates, setExcludeDates] = useState();
    const [personnel, setPersonnel] = useState();
    const [timeslots, setTimeslots] = useState()
    const personNumber = [];
    for (var i = 1; i <= 10; i++) {
        personNumber.push(i);
    }

    useEffect(() => {
        bookingService.merchantSettings().then(response => {
            setMerchantInfo(response.data);
            if (response.data.storeBankHolidays) {
                let removedDays = [];
                const currentYear = new Date().getFullYear();
                response.data.storeBankHolidays.forEach(item => {
                    removedDays.push(new Date(currentYear, item.month - 1, item.day))
                })
                setExcludeDates(removedDays)
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        register(
            { name: "date" },
            { required: "Campo obbligatorio" },
        )
        setValue("date", date);
    }, [register, date, setValue])

    const handleChange = date => {
        setValue("date", date);
        setDate(date);
        if (date) {
            errors.date = {};
            errors.date.message = "";
        } else {
            errors.date = {};
            errors.date.message = "Campo obbligatorio";
        }
        const dateString = Date.parse(date);
        const newDate = new Date(dateString);
        const weekDay = newDate.getDay() + 1
        if (weekDay) {
            bookingService.getTimeslots(weekDay)
                .then(response => {
                    setTimeslots(response.data)
                }).catch(error => {
                    console.log(error)
                })
        } else {
            setTimeslots(null)
        }
    }

    const onSubmit = data => {
        if (props.user) {
            if (watch("timeslot") !== undefined) {
                data.storeId = merchantInfo.storeId;
                data.bookingType = merchantInfo.bookingType;
                data.personnelId = personnel;
                bookingService.reserve(data)
                    .then(response => {
                        setShow(true);
                        setReserveInfo(response.data)
                    }).catch(error => {
                        console.log(error)
                        swal.error(error.message);
                    })
            } else {
                toaster.standard("Nessuno slot disponibile per il giorno selezionato")
            }
        } else {
            props.showModalLogin();
            // toaster.standard("Devi accedere per poter effettuare una prenotazione")
        }
    }

    const buttonDisabled = () => {
        if (
            watch("firstName") !== "" && watch("lastName") !== "" &&
            watch("mobilePhone") !== "" && watch("email") !== "" &&
            watch("timeslot") !== "" && date !== null && watch("accept1") === true
        ) return false;
        else
            return true;
    }

    console.log(watch())


    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`booking-container needs-validation ${errors ? "had-validation" : ""}`}>
                    <div className="booking-banner" style={{ backgroundImage: `url(${BookingBanner})` }} alt="" />
                    <div className="booking">
                        <div className="booking-title">
                            {t(`booking.title.${merchantInfo && merchantInfo.bookingType}`)}
                        </div>
                        <div className="booking-view mx-auto">
                            <div className="booking-subtitle">Inferisci le informazioni di contatto</div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label="Nome*"
                                        type="text"
                                        watch={watch("firstName")}
                                        value={props.user && props.user.firstName}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label="Cognome*"
                                        type="text"
                                        watch={watch("lastName")}
                                        value={props.user && props.user.lastName}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="email"
                                        label="Indirizzo email*"
                                        type="email"
                                        watch={watch("email")}
                                        value={props.user && props.user.email}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Indirizzo email non valido"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.username && errors.username.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="mobilePhone"
                                        label="Telefono*"
                                        type="tel"
                                        watch={watch("mobilePhone")}
                                        value={props.user && props.user.mobilePhone}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.mobilePhone && errors.mobilePhone.message}
                                    </div>
                                </Col>
                            </Row>
                            {merchantInfo && merchantInfo.bookingType === "NO_FOOD" &&
                                <>
                                    <div className="booking-subtitle">Seleziona il tuo barbiere di fiducia</div>
                                    <div className="grid_1">
                                        {merchantInfo && merchantInfo.personnel && merchantInfo.personnel.map(person => (
                                            <button
                                                key={person.id}
                                                type="button"
                                                className={`outline ${person.id === personnel ? "selected" : ""} `}
                                                onClick={() => {
                                                    if (person.id === personnel)
                                                        setPersonnel(null)
                                                    else
                                                        setPersonnel(person.id)
                                                }}
                                            >
                                                {person.name}
                                            </button>
                                        ))}
                                    </div>
                                </>
                            }
                            <Row>
                                {merchantInfo && merchantInfo.bookingType === "NO_FOOD" &&
                                    <Col md={6} className="form-group">
                                        <div className="booking-subtitle">Seleziona Servizio</div>
                                        <label className="select-label">Servizio*</label>
                                        <select className="booking-select" name="service" ref={register({ required: true })}>
                                            {merchantInfo && merchantInfo.personnel && merchantInfo.storeActivities.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.description} { item.duration ? `(${item.duration} minuti)` : null}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="form-control-invalid">
                                            {errors.service && errors.service.message}
                                        </div>
                                    </Col>
                                }
                                {merchantInfo && merchantInfo.bookingType === "FOOD" &&
                                    <Col md={6} className="form-group">
                                        <div className="booking-subtitle">Numero commensali</div>
                                        <label className="select-label">Adulti*</label>
                                        <select className="booking-select" name="personsNum" ref={register({ required: true })}>
                                            {personNumber && personNumber.map(item => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="form-control-invalid">
                                            {errors.personsNum && errors.personsNum.message}
                                        </div>
                                    </Col>
                                }
                                <Col md={6} className="form-group">
                                    <div className="booking-subtitle">Seleziona il giorno</div>
                                    <Row>
                                        <Col md={8}>
                                            <label className="select-label">Giorno*</label>
                                            <Datepicker
                                                label="Giorno*"
                                                onChange={handleChange}
                                                value={date}
                                                excludeDates={excludeDates}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                            />
                                            <div className="form-control-invalid">
                                                {errors.date && errors.date.message}
                                            </div>
                                        </Col>
                                        {timeslots &&
                                            <Col md={4}>
                                                <label className="select-label">Ore*</label>
                                                <select className="booking-select" name="timeslot" ref={register({ required: true })}>
                                                    {timeslots && timeslots.map(item => (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="form-control-invalid">
                                                    {errors.timeslot && errors.timeslot.message}
                                                </div>
                                            </Col>
                                        }
                                        {!timeslots && date &&
                                            <Col md={4}>
                                                <label className="no-timeslots">
                                                    Nessuno slot disponibile per il giorno selezionato
                                                </label>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            <div className="booking-subtitle">Note</div>

                            <textarea
                                name="notes"
                                ref={register}
                                className="booking-notes w-100"
                                rows="4"
                                placeholder="Scrivi qui le tue richieste"
                            />

                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                />
                                <label className="custom-control-label" htmlFor="accept1">
                                    Accetto le condizioni generali di prenotazione
                                </label>
                                <div className="form-control-invalid">
                                    {errors.accept1 && errors.accept1.message}
                                </div>
                            </div>
                            <div className="text-right">
                                <button className="cta" type="submit" disabled={buttonDisabled()}>
                                    <span>INVIA RICHIESTA PRENOTAZIONE</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <SuccessPopup
                        show={show}
                        setShow={setShow}
                        reserveInfo={reserveInfo}
                    />
                </form>
            }
        </Translation >
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showModalLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);



