import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoginPopup from './features/Login/LoginPopup/LoginPopup';
import Home from './features/Home/Home';
import Cart from "./features/Cart/Cart"
import Signup from "./features/Signup/Signup"
import CartCreate from "./features/CartCreate/CartCreate"
import CartUpdate from './features/CartUpdate/CartUpdate';
import Stores from "./features/Stores/Stores"
import Product from "./features/Product/Product"
import Checkout from './features/Checkout/Checkout';
import Orders from './features/Orders/Orders';
import Favorites from './features/Favorites/Favorites';
import SearchCategory from './features/SearchCategory/SearchCategory';
import Smartlist from './features/Smartlist/Smartlist';
import NewSearch from './features/NewSearch/NewSearch';
import PasswordReset from './features/PasswordReset/PasswordReset';
import ProfileContainer from './features/ProfileContainer/ProfileContainer';
import Payments from './features/Payments/Payments';
import Booking from './features/Booking/Booking';
import { authenticationService } from "./services/basis/AuthenticationService"
import config from './config/config';
import qs from "query-string";

import './App.scss';

// custom router
import { Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faPlus, faClipboardList, faCopy, faCreditCard,
  faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faChevronRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons'


library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList, faCopy, faChevronRight, faChevronDown,
  faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faCreditCard, fab.faFacebookSquare)

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
  componentDidMount() {
    const token = qs.parse(history.location.search).token;
    if (token) {
      this.props.tokenLogin(token)
    }
    if (this.props.user) {
      authenticationService.isLogged()
        .then(response => {
          this.props.current();
          if (config.ENABLED_SLIST) {
            this.props.getSlists();
            this.props.getFavorites();
          }
        })
        .catch((error) => {
          this.props.logout();
        })
    }
  }

  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <MainTemplate>
              <Switch>
                <Route exact path='/' component={Booking} />
                <Route exact path='/signup' component={Signup} />
                <Route exact path='/profile' component={ProfileContainer} />
                <Route exact path='/profile/addresses' component={ProfileContainer} />
                <Route exact path='/profile/billing' component={ProfileContainer} />
                <Route exact path='/profile/password' component={ProfileContainer} />
                <Route exact path='/reset' component={PasswordReset} />
              </Switch>
            </MainTemplate>
          </Switch>
          <LoginPopup
            open={this.props.showLogin}
            closePopup={this.props.closePopup}
          />
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    showLogin: state.user.showLogin
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    availability: () => dispatch(actionCreators.availability()),
    logout: () => dispatch(actionCreators.logout()),
    getFavorites: () => dispatch(actionCreators.getFavorites()),
    getSlists: () => dispatch(actionCreators.getSlists()),
    closePopup: () => dispatch(actionCreators.showLogin(false)),
    tokenLogin: (token) => dispatch(actionCreators.tokenLogin(token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
