import React from "react";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import MyStores from '../../../../images/MyStores.png';
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';

import "./mobile-header.styles.scss";

class MobileHeader extends React.Component {
    render() {
        const { user, closeAll, showModalLogin, logoUrl } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">
                                <div className="row mobile-header-top">
                                    <div className="col-8 pin">
                                        <a href="https://my-stores.it" target="_blank" rel="noopener noreferrer">
                                            <img src={MyStores} alt="my-stores" />
                                        </a>
                                    </div>
                                    <div className="col-4 text-right">
                                        {user ?
                                            <div className="col user">
                                                <div className="user-logged-container" onClick={this.props.loginModal}>
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col user text-right">
                                                <UserIcon onClick={showModalLogin} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row mobile-header-bottom">
                                    <div className="col-4">
                                        <div className="logo align-items-center">
                                            <Link to="" onClick={closeAll}>
                                                {/* <img src={Logo} alt="" /> */}
                                                {/* <Logo /> */}
                                                <img src={logoUrl} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="icons"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default withRouter(MobileHeader)